<template>
  <div v-if="isAvailable" class="mt-5">
    <div
      v-if="buttons"
      class="d-flex justify-center"
      :class="$root.$i18n.locale === 'ar' && 'flex-row-reverse'"
    >
      <v-btn
        light
        large
        color="primary"
        class="mx-3"
        depressed
        v-for="lang in items"
        :key="lang.value"
        :disabled="$root.$i18n.locale === lang.value"
        @click="setLang(lang.value)"
        >{{ lang.text }}</v-btn
      >
    </div>
    <div v-else style="width: 180px">
      <v-select
        light
        :items="items"
        outlined
        prepend-inner-icon="mdi-translate"
        :value="$i18n.locale"
        dense
        hide-details
        @input="setLang"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  props: {
    buttons: { type: Boolean, default: false }
  },
  computed: {
    isAvailable() {
      return this.$i18n.availableLocales.length > 1;
    },
    items() {
      return this.$i18n.availableLocales.map(i => {
        return { text: this.$t('languageNames.' + i), value: i };
      });
    }
  },
  methods: {
    setLang(lang) {
      localStorage.setItem('lang', lang);
      this.$root.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
      this.$vuetify.rtl = lang === 'ar';
    }
  }
};
</script>

<style></style>
