export function SliderComponent() {
  this.type = 'slider'
  //this.render = async function (elementTemplate, containers, controller, path) {
  this.render = async function (elementTemplate, containers, controller) {
    let {
      id,
      attributes,
      styles,
      properties
    } = elementTemplate
    attributes = attributes || []

    let {
      slideUrl,
      slideInterval,
      animationDuration
    } = properties

    slideUrl = controller.normalizeParameter(slideUrl || '')
    slideInterval = +slideInterval || 5000 //ms
    animationDuration = animationDuration || '.5s'

    controller.insertDocumentStyle(`.slider-container {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
          }

          .slide {
            width: 100%;
            height: 100%;
          }
      
          .slide img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
      
          .slide {
            position: absolute;
          }

          @keyframes fade {
            from {
              opacity: .4
            }
      
            to {
              opacity: 1
            }
          }`)

    const fadeClassName = id + 'fade'

    controller.insertDocumentStyle(`.${fadeClassName} {
            animation-name: fade;
            animation-duration: ${animationDuration};
          }`)

    let slideHtml = controller.normalizeParameter(await controller.loadHtml(slideUrl))

    const myContainer = controller.prepareElement('div', containers[containers.length - 1], attributes, styles)
    myContainer.className += ' slider-container fz'+this.type 
    myContainer.innerHTML = slideHtml

    const slides = myContainer.querySelectorAll('.slide')
    Array.from(slides).forEach(element => element.className += ' ' + fadeClassName)

    let slideIndex = 0

    showSlides(slideIndex)

    function showSlides(index) {
      if (index >= slides.length)
        slideIndex = 0
      else
      if (index < 0)
        slideIndex = slides.length - 1
      slides.forEach(slide => slide.style.display = 'none')
      slides[slideIndex].style.display = 'block'
    }

    let sliderTimer = setInterval(function () {
      showSlides(++slideIndex)
    }, slideInterval)

    controller.registerClearCallback(containers[0].id, () => clearInterval(sliderTimer))

    return {
      elementTemplate,
      container: myContainer
    }
  }

}