import Vue from 'vue';
import Vuex from 'vuex';
import settings from '../settings';
// modules
import auth from './auth';
// import questions from './questions'
// import polls from './polls'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    settings
  },
  mutations: {},
  actions: {},
  modules: {
    auth
  }
});
