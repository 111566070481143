<template>
  <v-banner
    class="cookiebanner"
    v-model="cookiebanner"
    transition="slide-y-transition"
  >
    {{ $t('cookie.info') }}
    <TermsDialog>
      {{ $t('cookie.more') }}
    </TermsDialog>
    <template v-slot:actions="{}">
      <v-btn class="ma-2" color="primary" @click="allowCookies">
        {{ $t('welcome.proceed') }}
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import TermsDialog from '@/components/TermsDialog';

export default {
  name: 'CookieBar',
  components: { TermsDialog },
  data() {
    return {
      cookiebanner: false && !localStorage.getItem('cookiebanner')
    };
  },
  methods: {
    allowCookies() {
      this.cookiebanner = false;
      localStorage.setItem('cookiebanner', 'ok');
    }
  }
};
</script>

<style scoped>
.cookiebanner {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8) !important;
  backdrop-filter: blur(8px);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
