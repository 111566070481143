<template>
  <div>
    <router-view :style="styleVars" />
    <FlexChat />
  </div>
</template>

<script>
import './styles/main.css';
import FlexChat from '@/components/FlexChat';

export default {
  name: 'Rhine',
  components: { FlexChat },
  head() {
    return {
      title: 'World Live Neurovascular Conference',
      titleTemplate: '%s | WLNC'
    };
  },
  computed: {
    styleVars() {
      return {
        '--plyr-color-main': this.$store.state.settings.theme.primary
      };
    }
  },
  sockets: {
    app_refresh() {
      window.location.reload();
    }
  },
  created() {}
};
</script>

<style>
:root {
  --plyr-control-icon-size: 24px;
  --plyr-control-spacing: 12px;
}

.brand-border {
  border: 3px solid var(--plyr-color-main) !important;
}
</style>
