export default {
  baseUrl: process.env.VUE_APP_API_URL,
  siteId: process.env.VUE_APP_SITE_ID,
  multiLanguage: false,
  useBrowserLanguage: false,
  defaultLanguage: 'en',
  // phoneSupportLine: '+90(850) 302 2293',
  // phoneSupportLine: '+1 469 595 8634',
  flexChat: false,
  dark: false,
  theme: {
    primary: '#2E2E85',
    accent: '#3DA613'
  },
  questions: {
    enabled: false,
    visible: true,
    moderation: false
  },
  voting: {
    enabled: false
  }
};
