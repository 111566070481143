import axios from 'axios';
import settings from '../settings';
import store from '../store';

const instance = axios.create({
  baseURL: settings.baseUrl
});

instance.interceptors.request.use(async config => {
  if (!config.headers.Authorization && store.state.auth.token) {
    if (store.state.auth.expiration < Date.now() - 60000) {
      await store.dispatch('auth/refresh');
    }
    config.headers.Authorization = `Bearer ${store.state.auth.token}`;
  }
  return config;
});

export default instance;
