import { extend, setInteractionMode, configure } from 'vee-validate';
import { email, required, length, numeric } from 'vee-validate/dist/rules';

setInteractionMode('eager');
extend('email', email);
extend('required', required);
extend('length', length);
extend('numeric', numeric);

export function configureTranslationsForValidation({ i18n }) {
  configure({
    defaultMessage: (field, values) => {
      values._field_ = i18n.t(`fields.${field}`);
      return i18n.t(`validations.${values._rule_}`, values);
    }
  });
}
