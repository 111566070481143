<template>
  <div />
</template>

<script>
import { mapState } from 'vuex';

const msgStrings = [
  'EntryPointTagLine',
  'WelcomeMessage',
  'PredefinedChatMessageAuthorName',
  'PredefinedChatMessageBody',
  'InputPlaceHolder',
  'Today',
  'Read',
  'MessageCanvasTrayContent',
  'MessageCanvasTrayButton'
];

export default {
  name: 'FlexChat',
  head() {
    return {
      script: [
        {
          src:
            'https://assets.flex.twilio.com/releases/flex-webchat-ui/2.8.1/twilio-flex-webchat.min.js',
          integrity:
            'sha512-KzpB56iRohSbDOkfM/V0PTp9DGHMno2EJJx6Zg8Ul3byOV3xtAurIZ+NibcO+cc0SEDvodI/5SKMSv2p+gwSYw==',
          crossorigin: true,
          async: true
        }
      ]
    };
  },
  data() {
    return {
      manager: null
    };
  },
  mounted() {
    this.checkLoading();
  },
  watch: {
    '$root.$i18n.locale'() {
      this.updateLanguage();
    },
    'profile.email'() {
      this.updateLanguage();
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.user
    })
  },
  methods: {
    checkLoading() {
      if (typeof window.Twilio === 'undefined') {
        setTimeout(this.checkLoading, 100);
        return;
      }
      if (this.$store.state.settings?.flexChat) {
        this.initialize();
      }
    },
    generateContext() {
      const ctx = { locale: this.$root.$i18n.locale };
      if (this.profile) {
        ctx.friendlyName = this.profile.name || this.profile.email;
        ctx.email = this.profile.email;
        ctx.fullName = this.profile.name;
      }
      return ctx;
    },
    generateComponentProps() {
      return {
        EntryPoint: {
          tagline: this.$t('support.EntryPointTagLine')
        },
        MainHeader: {
          titleText: this.$t('support.EntryPointTagLine'),
          showImage: false
        },
        MessagingCanvas: {
          PredefinedMessage: {
            body: this.$t('support.PredefinedChatMessageBody'),
            authorName: this.$t('support.PredefinedChatMessageAuthorName')
          }
        }
      };
    },
    updateStrings() {
      if (!this.manager) {
        return;
      }
      msgStrings.forEach(i => {
        this.manager.strings[i] = this.$t(`support.${i}`);
      });
    },
    initialize() {
      window.Twilio.FlexWebChat.createWebChat({
        accountSid: 'AC0b8bfba14fc6354296cc7c6914413e13',
        flexFlowSid: 'FO00ce457537b29eb9b11deebccc33ca9b',
        componentProps: this.generateComponentProps(),
        context: this.generateContext(),
        colorTheme: {
          overrides: {
            EntryPoint: {
              Container: {
                background: this.$store.state.settings.theme.primary,
                color: '#ffffff',
                '&:hover': {
                  background: this.$store.state.settings.theme.primary
                }
              }
            }
          }
        }
      }).then(webchat => {
        this.manager = webchat.manager;
        this.updateStrings();
        webchat.init();
      });
    },
    updateLanguage() {
      if (!this.manager) {
        return;
      }
      this.updateStrings();
      this.manager.updateConfig({
        componentProps: this.generateComponentProps(),
        context: this.generateContext()
      });
      this.manager.setStringsFromConfig({ language: this.$root.$i18n.locale });
      this.manager.loadInitialData();
    }
  }
};
</script>

<style>
.Twilio-RootContainer {
  font-family: 'NotoSansArabicUI', 'MarkPro', 'Open Sans', sans-serif !important;
}
</style>
