import decodeJwt from 'jwt-decode';
import axios from '../plugins/axios';
import socket from '../plugins/socket';
import { initials } from '../lib/utils';

const configureSocket = token => {
  if (token) {
    socket.io.opts.query = { token };
    if (socket.disconnected) {
      socket.open();
    } else {
      socket.once('disconnect', () => {
        socket.open();
      });
      socket.close();
    }
  } else {
    socket.io.opts.query = null;
    socket.close();
  }
};

export default {
  namespaced: true,
  state: () => {
    return {
      isInitialized: false,
      isLoading: true,
      token: null,
      expiration: null,
      decoded: null,
      user: null,
      timeoutId: null,
      error: null
    };
  },
  getters: {
    isAuthenticated: s => !!s.token,
    isAdmin: s => s.decoded?.role === 'admin',
    initials: s => initials(s.user?.email, s.user?.name)
  },
  mutations: {
    SET_TIMEOUT(state, id) {
      if (state.timeoutId) {
        clearTimeout(state.timeoutId);
      }
      state.timeoutId = id;
    },
    AUTH_REQUEST(state) {
      state.error = null;
      state.isLoading = true;
    },
    AUTH_SUCCESS(state, response) {
      state.isInitialized = true;
      state.isLoading = false;
      state.error = null;
      state.token = response.accessToken;
      state.decoded = decodeJwt(response.accessToken);
      state.expiration = state.decoded.exp * 1000;
      state.user = decodeJwt(response.idToken);
      localStorage.setItem('rhine:refresh_token', response.refreshToken);
    },
    AUTH_LOGOUT(state) {
      state.isInitialized = true;
      state.isLoading = false;
      state.token = null;
      state.decoded = null;
      state.expiration = null;
      state.user = null;
      localStorage.removeItem('rhine:refresh_token');
    },
    AUTH_ERROR(state, response) {
      state.isInitialized = true;
      state.isLoading = false;
      state.error = response;
    }
  },
  actions: {
    async requestOtp({ rootState }, requestData) {
      return axios.post('/auth/requestotp', {
        ...requestData,
        siteId: rootState.settings.siteId
      });
    },
    async login({ commit, rootState, dispatch, state }, loginData) {
      commit('AUTH_REQUEST');
      try {
        const { data } = await axios.post('/auth/login', {
          ...loginData,
          siteId: rootState.settings.siteId
        });
        commit('AUTH_SUCCESS', data);
        const timeoutId = setTimeout(() => {
          dispatch('refresh');
        }, state.expiration - Date.now() - 60 * 1000);
        commit('SET_TIMEOUT', timeoutId);
        configureSocket(state.token);
      } catch (e) {
        console.error('AUTHENTICATION FAILED');
        console.error(e);
        commit('AUTH_ERROR', e.response.data);
        throw e;
      }
    },
    async refresh({ commit, dispatch, state }) {
      commit('AUTH_REQUEST');
      try {
        const { data } = await axios.post(
          '/auth/refresh',
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                'rhine:refresh_token'
              )}`
            }
          }
        );
        commit('AUTH_SUCCESS', data);
        const timeoutId = setTimeout(() => {
          dispatch('refresh');
        }, state.expiration - Date.now() - 60 * 1000);
        commit('SET_TIMEOUT', timeoutId);
        configureSocket(state.token);
      } catch (e) {
        console.error('TOKEN REFRESH FAILED');
        console.error(e);
        commit('AUTH_ERROR', e.response.data);
        await dispatch('logout');
        throw e;
      }
    },
    async logout({ commit }) {
      commit('SET_TIMEOUT', null);
      commit('AUTH_LOGOUT');
      configureSocket(null);
    },
    async initialize({ dispatch }) {
      if (localStorage.getItem('rhine:refresh_token')) {
        await dispatch('refresh');
      }
    }
  }
};
