export default async ({ to, next, store }) => {
  try {
    if (!store.state.auth.isInitialized) {
      await store.dispatch('auth/initialize');
    }
    if (
      store.state.auth.expiration &&
      store.state.auth.expiration < Date.now() - 60000
    ) {
      await store.dispatch('auth/refresh');
    }
  } catch {
    console.log('auth middleware actions failed');
  }

  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const isAdmin = store.getters['auth/isAdmin'];

  if (to.matched.some(i => i.meta.guest)) {
    if (isAuthenticated) {
      next({ path: '/stage/main' });
    } else {
      next();
    }
  } else if (to.matched.some(i => i.meta.auth === false)) {
    next();
  } else {
    if (!isAuthenticated) {
      next({
        path: '/login',
        params: { redirect: to.fullPath }
      });
    } else {
      if (to.matched.some(i => i.meta.admin === true) && !isAdmin) {
        next({ path: '/stage/main' });
      }
      next();
    }
  }
};
