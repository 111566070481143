export function BackgroundComponent() {
  this.type = 'background'
  //this.render = async function (elementTemplate, containers, controller, path) {
  this.render = async function (elementTemplate, containers, controller) {
    let {
      attributes,
      styles
    } = elementTemplate
    attributes = attributes || []

    controller.insertDocumentStyle(`.fz-parent{
            display: flex;
            justify-content: center;
            align-items: center;
          }`)

    const myContainer = controller.prepareElement('div', containers[containers.length-1], attributes, styles)
    myContainer.className += ' fz-parent fz-'+this.type 
    return {
      elementTemplate,
      container: myContainer
    }
  }

}