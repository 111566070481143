<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs centered>
          <v-tab to="/stage/main/1">Day One</v-tab>
          <v-tab to="/stage/main/2">Day Two</v-tab>
        </v-tabs>
        <router-view />
        <v-row align="center" class="mt-5">
          <v-col cols="12" md="5" class="text-overline">
            <v-row>
              <v-col class="py-0 pl-6 sponsorheadline">
                Platinium Sponsors
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0" cols="6">
                <v-img
                  contain
                  height="35"
                  :src="require('@/assets/logos_1/medtronic.png')"
                />
              </v-col>
              <v-col class="pt-0" cols="6">
                <v-img
                  contain
                  height="50"
                  :src="require('@/assets/logos_1/micro.png')"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="7" class="text-overline">
            <v-row>
              <v-col class="py-0 pl-3 sponsorheadline" cols="3">
                Diamond
              </v-col>
              <v-col class="py-0 pl-10 sponsorheadline" cols="3">
                Gold
              </v-col>
              <v-col class="py-0 pl-3 sponsorheadline" cols="3">
                Silver
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0" cols="3">
                <v-img
                  contain
                  height="40"
                  :src="require('@/assets/logos_1/siemens.png')"
                />
              </v-col>
              <v-col class="pt-0" cols="3">
                <v-img
                  contain
                  height="45"
                  :src="require('@/assets/logos_1/balt.png')"
                />
              </v-col>
              <v-col class="pt-0" cols="1">
                <v-img
                  contain
                  height="40"
                  :src="require('@/assets/logos_1/philips.png')"
                />
              </v-col>
              <v-col class="pt-0" cols="1">
                <v-img
                  contain
                  height="40"
                  :src="require('@/assets/logos_1/vesalio.png')"
                />
              </v-col>
              <v-col class="pt-0 r" cols="2">
                <v-img
                  contain
                  height="20"
                  :src="require('@/assets/logos_1/phenox.png')"
                />
              </v-col>
              <v-col class="pt-0" cols="2">
                <v-img
                  contain
                  height="20"
                  :src="require('@/assets/logos_1/imperative.png')"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Stage',
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  beforeDestroy() {},
  head() {
    return {
      title: 'Main Stage'
    };
  }
};
</script>

<style></style>
