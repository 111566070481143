<template>
  <div>
    <v-card elevation="15">
      <ValidationObserver ref="observer" v-slot="{}">
        <v-form @submit.prevent="verify">
          <v-card-text class="px-6 pb-0">
            <v-alert class="text--white" text>
              {{ $t('verification.info') }}
            </v-alert>
            <ValidationProvider
              v-slot="{ errors }"
              name="verification"
              rules="required|numeric|length:6"
            >
              <v-text-field
                v-model="otp"
                maxlength="6"
                minlength="6"
                outlined
                :label="$t('verification.label')"
                name="code"
                :disabled="busy"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
            <v-alert v-if="serverError" type="error" text>
              {{ $t('errors.' + msgCode) }}
            </v-alert>
          </v-card-text>
          <v-card-actions class="px-6 pb-6 pt-0">
            <v-row>
              <v-col cols="12">
                <v-btn
                  :loading="busy"
                  :disabled="busy"
                  depressed
                  block
                  color="primary"
                  large
                  type="submit"
                >
                  {{ $t('verification.verify') }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-divider />
                <div class="text-caption text-center mt-2 mb-2">
                  {{ $t('verification.not_received') }}
                </div>
                <v-btn
                  :disabled="busy || disableSend"
                  text
                  block
                  small
                  @click="sendAgain"
                >
                  {{ $t('verification.send_again') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'OtpForm',
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      email: '',
      otp: '',
      busy: false,
      serverError: null,
      msgCode: null,
      disableSend: false
    };
  },
  created() {
    if (
      this.$route.query.lang &&
      this.$i18n.availableLocales.includes(this.$route.query.lang)
    ) {
      this.$root.$i18n.locale = this.$route.query.lang;
      this.$vuetify.lang.current = this.$route.query.lang;
    }

    this.email = this.$route.query.email || '';
    this.otp = this.$route.query.otp || '';
    const proceed = this.$route.query.proceed;
    // this.$router.replace({ query: { email: this.$route.query.email } })

    if (proceed) {
      this.verify();
    }
  },
  methods: {
    async verify() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }

      this.busy = true;
      try {
        await this.$store.dispatch('auth/login', {
          email: this.email,
          otp: this.otp
          // siteId: this.$store.state.info?.id,
        });
        this.$router.push({ path: '/', query: null });
      } catch (e) {
        this.serverError = e.response?.data?.message || e.message;
        this.msgCode = e.response?.data?.type;
      } finally {
        this.busy = false;
      }
    },
    async sendAgain() {
      try {
        await this.$store.dispatch('auth/requestOtp', {
          email: this.email,
          returnUrl: window.location.origin + '/enter-code',
          lang: this.$i18n.locale
        });
        this.disableSend = true;
      } catch (e) {
        console.error(e);
        this.serverError = e.response?.data?.message || e.message;
        this.msgCode = e.response?.data?.type;
      }
    }
  }
};
</script>
