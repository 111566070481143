import {
  BackgroundComponent
} from './comps/back.js'

import {
  MarkerComponent
} from './comps/marker.js'

import {
  SliderComponent
} from './comps/slider.js'
/*
import {
  JanusClientComponent
} from './comps/januscli.js'
*/

function ContextAdapter2() {
  this.id = 'test'
  this.callMethod = function (requestInfo, sender, name, parameters) {
    return new Promise((resolve) => {
      if (name === 'testFunction') {
        const [intParam, strParam, objParam] = parameters
        alert(`callmethod intParam:${intParam}, strParam:${strParam}, objParam:${JSON.stringify(objParam)}`)

        return resolve('OK')
      }
      resolve()
    })

  }

}

function ContextAdapter() { // Adapter methods must return a different result from undefined.
  this.id = 'default'
  this.variables = new Map()
  this.prepare = async function (url) {
    this.variables = new Map((await loadJSON(url) || {
      vars: []
    }).vars)
  }

  this.callMethod = function (requestInfo, sender, name, ...parameters) {
    return new Promise((resolve) => {
      if (name === 'alert') {
        alert(...parameters)
        return resolve('OK')
      }
      resolve()
    })

  }

  this.updateData = function (requestInfo, sender, name, value) {
    return new Promise((resolve) => {
      const key = `${requestInfo.path}/${sender.id}.${name}`
      console.log('updateData key:', key, 'value:', value)
      this.variables.set(key, value)
      resolve('OK')
    })

  }
  
  //this.queryData = function (requestInfo, sender, name, parameters) {
  this.queryData = function (requestInfo, sender, name) {
    return new Promise((resolve, reject) => {
      try {
        const key = `${requestInfo.path}/${sender.id}.${name}`
        console.log('queryData key:', key)
        resolve(this.variables.get(key))
      } catch (err) {
        reject(err)
      }
    })
  }
}

const hashCode = s => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0)

function DOMRenderer(controller) {
  this.renderTo = async function (model, containers, path) {

    if (!Array.isArray(containers))
      containers = [containers]

    path = path || ''
    for (const elementTemplate of (model.elements || [])) {
      let renderResult = await controller.renderComponent(elementTemplate, containers, path)
      controller.setContextElement(elementTemplate.id, renderResult.context)

      if (elementTemplate.elements)
        await this.renderTo(elementTemplate, [...containers, renderResult.container], path + '/' + elementTemplate.id)
    }
  }
}

export async function renderControl(rootUrl, container, beforeClear, injectAdapter) {

  const rnd = Math.random()
  const designUrl = rootUrl + '/design.json?rnd=' + rnd
  const varsUrl = rootUrl + '/design_vars.json?rnd=' + rnd

  const controller = new Controller(rootUrl)

  const contextAdapter = new ContextAdapter()
  const results = await Promise.all([loadJSON(designUrl), contextAdapter.prepare(varsUrl)])

  controller.registerAdapter(contextAdapter)
  controller.registerAdapter(new ContextAdapter2())
  if (injectAdapter) {
    controller.registerAdapter(injectAdapter)
  }

  controller.registerComponent(new BackgroundComponent())
  controller.registerComponent(new MarkerComponent())
  controller.registerComponent(new SliderComponent())
  // controller.registerComponent(new JanusClientComponent())

  const model = results[0]
  const renderer = new DOMRenderer(controller)
  if (beforeClear && container) {
    runClearCallbacks(container.id)

    while (container.firstChild)
      container.removeChild(container.firstChild)
  }

  // const testInit = function () {
  //   let counter = 0;
  //   let timerHandle =
  //     setInterval(() => {
  //       controller.runChangeHandlers('/model1/janus1.caption', 'bind debug - ' + ++counter);
  //       if (counter >= 10)
  //         clearInterval(timerHandle);
  //     }, 1000);
  // }
  //  testInit();

  return renderer.renderTo(model, container)
}
const CLEAR_CALLBACKS = new Map()

function registerClearCallback(containerId, callback) {
  let callbacks = CLEAR_CALLBACKS.get(containerId)
  callbacks = callbacks || []
  callbacks.push(callback)
  CLEAR_CALLBACKS.set(containerId, callbacks)
}

function runClearCallbacks(containerId) {
  const callbacks = CLEAR_CALLBACKS.get(containerId)
  if (callbacks)
    callbacks.forEach(callback => callback())
}

const toCamel = prop =>
  prop.replace(/-(\w|$)/g, (dash, next) => next.toUpperCase())

var lastIdentifier = 0

function Controller(rootUrl) {
  this.rootUrl = rootUrl
  const changeHandlers = new Map()
  const adapters = []
  const contextElements = new Map()
  const components = new Map()
  this.renderControl = renderControl
  this.hashCode = hashCode
  this.loadHtml = loadHtml
  this.loadJSON = loadJSON
  this.registerClearCallback = registerClearCallback
  this.getUid = () => '' + ++lastIdentifier

  this.applyStyles = function (element, styles) {
    styles.forEach(style => element.style[toCamel(style[0])] = this.normalizeParameter(style[1]))
  }

  this.applyAttributes = function (element, attributes) {
    attributes.forEach(attribute => element.setAttribute(attribute[0], this.normalizeParameter(attribute[1])))
  }

  this.normalizeParameter = function (value) {
    return typeof value === 'string' ? value.replaceAll('{BASE}', rootUrl) : value
  }

  this.registerAdapter = function (adapter) {
    if (adapters.findIndex(v => v.id === adapter.id) === -1)
      adapters.unshift(adapter)
  }
  this.registerComponent = function (component) {
    components.set(component.type, component)
  }

  this.renderComponent = async function (elementTemplate, containers, path) {
    const component = components.get(elementTemplate.type)
    if (!component) throw new Error('Invalid component type:' + elementTemplate.type)

    return component.render(elementTemplate, containers, this, path)
  }
  this.setContextElement = (id, ctx) => contextElements.set(id, ctx)
  this.getContextElementById = (id) => contextElements.get(id)

  this.getHelper = (path, sender) => ({
    identifier: '' + ++lastIdentifier,
    callMethod: async function (name, ...args) {
      for (const adapter of adapters) {
        if (!adapter.callMethod) continue
        let callResult = await adapter.callMethod({
          path
        }, sender, name, ...args)
        if (callResult !== undefined) {
          return callResult
        }
      }
    },

    queryData: async function (...args) {
      for (const adapter of adapters) {
        if (!adapter.queryData) continue
        let queryResult = await adapter.queryData({
          path
        }, sender, ...args)
        if (queryResult !== undefined) {
          return queryResult
        }
      }
    },

    registerChangeHandler: function (name, callback) {
      const key = `${path}/${sender.id}.${name}`
      let changeHandlerList = changeHandlers.get(key)
      if (!changeHandlerList) {
        changeHandlerList = new Map()
        changeHandlers.set(key, changeHandlerList)
      }
      changeHandlerList.set(this.identifier, callback)
    },

    unregisterChangeHandler: function (name) {
      const key = `${path}/${sender.id}.${name}`
      let changeHandlerList = changeHandlers.get(key)
      if (!changeHandlerList) return
      changeHandlerList.delete(this.identifier)
    },

    updateData: async function (...args) {
      for (const adapter of adapters) {
        if (!adapter.updateData) continue
        let updateResult = await adapter.updateData({
          path
        }, sender, ...args)
        if (updateResult !== undefined) {
          return updateResult
        }
      }
    }
  })

  this.runChangeHandlers = function (key, newValue) {
    let changeHandlerList = changeHandlers.get(key)
    if (changeHandlerList)
      changeHandlerList.forEach(changeHandler => changeHandler(newValue))
  }

  this.insertDocumentStyle = function (style) {
    if (!style) return
    const key = 'dynstyle_' + hashCode(style)
    if (document.getElementById(key)) return

    const element = document.createElement('style')
    element.type = 'text/css'
    element.id = key
    element.innerHTML = style;
    (document.head || document.documentElement).appendChild(element)
  }

  this.insertDocumentStyleUrl = function (url) {
    const key = 'dynsurl_' + hashCode(url)
    if (document.getElementById(key)) return

    var element = document.createElement('link')
    element.id = key
    element.href = url
    element.rel = 'stylesheet'
    element.type = 'text/css';
    (document.head || document.documentElement).appendChild(element)
  }

  this.insertDocumentCodeUrl = function (url) {
    const key = 'dyncurl_' + hashCode(url)
    if (document.getElementById(key)) return Promise.resolve()

    return new Promise((resolve, reject) => {
      var element = document.createElement('script')
      element.id = key
      element.setAttribute('type', 'text/javascript')
      element.setAttribute('src', url)
      element.addEventListener('load', resolve, false)
      element.addEventListener('onerror', reject, false);
      (document.head || document.documentElement).appendChild(element)
    })

  }

  this.prepareElement = function (tag, container, attributes, styles, prepend) {
    const element = document.createElement(tag)
    if (attributes)
      this.applyAttributes(element, attributes)

    if (container)
      container[prepend ? 'prepend' : 'appendChild'](element)

    if (styles)
      this.applyStyles(element, styles)

    return element
  }
}

async function loadHtml(url) {
  let response = await fetch(url)
  if (response.ok)
    return await response.text()
  throw new Error('loadHtml http error:' + response.status)

}

async function loadJSON(url) {
  let response = await fetch(url)
  if (response.ok)
    return await response.json()

  throw new Error('loadJSON http error:' + response.status)
}