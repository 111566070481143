<template>
  <div v-if="supportLine">
    <v-alert text class="grey lighten-3" icon="mdi-help-circle-outline">
      <i18n path="phoneSupportBox.havingTrouble">
        <a style="white-space: nowrap" :href="'tel:' + supportLine">{{
          supportLine
        }}</a>
      </i18n>
    </v-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'PhoneSupportBox',
  computed: {
    ...mapState({
      supportLine: s => s.settings?.phoneSupportLine
    })
  }
};
</script>
