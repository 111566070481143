import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages, { availableLocales } from '../locales';
import { getPreferredLocale } from '../lib/i18n';
import settings from '../settings';

Vue.use(VueI18n);

const prefferedLocale = getPreferredLocale(availableLocales, {
  useBrowserLocale: settings.useBrowserLanguage
});

export const locale = settings.multiLanguage
  ? prefferedLocale || settings.defaultLanguage
  : settings.defaultLanguage;

export default new VueI18n({
  locale,
  fallbackLocale: settings.defaultLanguage,
  availableLocales: settings.multiLanguage
    ? availableLocales
    : [settings.defaultLanguage],
  messages: settings.multiLanguage
    ? messages
    : { [settings.defaultLanguage]: messages[settings.defaultLanguage] }
});
