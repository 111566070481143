export function MarkerComponent() {
  this.type = 'marker'
  this.render = async function (elementTemplate, containers, controller, path) {
    const {
      id,
      attributes,
      styles,
      properties,
    } = elementTemplate

    let {
      bounceInterval,
      imageUrl,
      title,
      action
    } = properties

    bounceInterval = bounceInterval || '2s'
    action = action || {}

    const bounceClassName = 'fz-bouncer-' + id
    controller.insertDocumentStyle(`.${bounceClassName} {
            display: flex;
            flex-shrink: 0;
            animation: bouncing ${bounceInterval} infinite
          }
          @keyframes bouncing {
              50% {
                transform: scale(1.1);
              }
            }`)

    const helper = controller.getHelper(path, elementTemplate)
    
    let attrs = attributes || []
    const myContainer = controller.prepareElement('div', containers[containers.length - 1], [ ...attrs, ['id', id]])

    controller.applyStyles(myContainer, [
      ['display', 'flex'],
      ['background', controller.normalizeParameter(imageUrl)],
      ['cursor', 'pointer'],
      ['background-size', 'contain'],
      ['background-position', 'center'],
      ...styles
    ])
    myContainer.className += ' ' + bounceClassName + ' fz-' + this.type

    if (title)
      myContainer.innerHTML = title

    const actionHandlers = new Map([

      ['url', async function (evt, {
        url,
        name,
        specs,
        replace
      }) {
        window.open(url, name, specs, replace)
      }],
      ['showpdf', async function (evt, {
        fileUrl,
        name
      }) {
        window.open(fileUrl, name)
      }],
      ['showimage', async function (evt, {
        fileUrl,
        name
      }) {
        window.open(fileUrl, name)
      }],
      ['showvideo', async function (evt, {
        fileUrl,
        name
      }) {
        window.open(fileUrl, name)
      }],
      ['loaddesign', async function (evt, {
        url,
        containerName,
        beforeClear
      }) {
        window.setTimeout(() => {
          controller.renderControl(url, containerName ? document.getElementById(containerName) : containers[0], +beforeClear).catch(console.error)
        }, 0)
      }],
      ['testFunction', async function (evt, {
        intParam,
        strParam,
        objParam
      }) {
        alert(`internal intParam:${intParam}, strParam:${strParam}, objParam:${JSON.stringify(objParam)}`)
      }],
      ['callmethod', async function (evt, {
        name,
        parameters
      }) {
        helper.callMethod(name, parameters)
      }],
      ['startjanus', async function (evt, parameters
      ) {
        const janusClient = controller.getContextElementById(parameters.targetId)
        if (!janusClient) throw new Error('JanusClientControl not found! id:' + parameters.targetId)
        await janusClient.start(parameters)

      }],
      ['stopjanus', async function (evt, parameters
      ) {
        const janusClient = controller.getContextElementById(parameters.targetId)
        if (!janusClient) throw new Error('JanusClientControl not found! id:' + parameters.targetId)
        await janusClient.stop(parameters)
      }]

    ])

    myContainer.addEventListener('click', async (evt) => {
      const actionHandler = actionHandlers.get(action.type)
      if (!actionHandler)
        throw new Error('Invalid action type:' + action.type)
      await actionHandler(evt, action.parameters)
    })

    return {
      elementTemplate,
      container: myContainer
    }
  }

}