<template>
  <span>
    <a href="#" @click.stop="dialog = true"><slot /></a>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar color="navy" flat>
          <v-toolbar-title v-t="'login.kvkk_doc'" />
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-4 ma-0 white black--text" style="height: 80vh">
          <iframe :srcDoc="srcDoc" class="docframe" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
//import en from '!!raw-loader!../assets/tou.en.html';
//import tr from '!!raw-loader!../assets/tou.tr.html';
const docs = {
  //en,
  //tr
};

export default {
  name: 'Terms',
  props: ['lang'],
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    srcDoc() {
      return docs[this.lang] || docs.en;
    }
  }
};
</script>

<style scoped>
.docframe {
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
</style>
