export function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;
  if (!navigatorLocale) {
    return undefined;
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();
  return trimmedLocale;
}

export function getPreferredLocale(availableLocales = [], options = {}) {
  const defaultOptions = { useBrowserLocale: true };
  const opt = { ...defaultOptions, ...options };

  const previousSelection = localStorage.getItem('lang');

  if (previousSelection && availableLocales.includes(previousSelection)) {
    return previousSelection;
  }

  if (opt.useBrowserLocale) {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true });
    if (availableLocales.includes(browserLocale)) {
      return browserLocale;
    }
  }

  return null;
}
